import $ = require("jquery");
import "datatables.net";

export class PublicationsComponent {

    dataTable: any;

    constructor() {

        $("#public-publications-table").DataTable({
            "paging": true,
            "ordering": true,
            "info": true
        });
    }
}