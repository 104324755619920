
// SCRIPTS
import { SimpleResearchSiteMapComponent } from './research_sites/SimpleResearchSiteMap.component'; export { SimpleResearchSiteMapComponent };
import { PublicationsComponent } from './publications/publications.component'; export { PublicationsComponent };

//import { SinglePage } from './single-page/scripts/single.page'; export { SinglePage }; //to make discoverable from cshtml
//import { ContactUsPage } from './contact-us/contact-us.page'; export { ContactUsPage };
//import { FundingPage } from './funding/funding.page'; export { FundingPage };
//import { NewsEventsPage } from './news-events/news-events.page'; export { NewsEventsPage };
//import { AboutPage } from './about/about.page'; export { AboutPage };
//import { FaqsPage } from './faqs/faqs.page'; export { FaqsPage };

// STYLES
import './home/home.scss';
//import './single-page/single-page.scss';
//import './single-page/coming-soon.scss';
//import './contact-us/contact-us.scss';
//import './funding/funding.scss';
//import './news-events/news-events.scss';
import './about/about.scss';
//import './faqs/faqs.scss';

