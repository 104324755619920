import $ = require("jquery");
import L = require("leaflet");

import pin from './images/location-pin.svg';

import './res-sites.scss';
import { forEach } from "lodash";
import { icon } from "@fortawesome/fontawesome-svg-core";

export class SimpleResearchSiteMapComponent {
    private readonly _tileUrl: string = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    private readonly _markerDatasetUrl: string = '/public/research_sites/map';
    private readonly _color_classes = ['blue', 'green', 'orange', 'red', 'teal'];


    map: L.Map;
    mapData: SimpleMapData[];

    constructor(mapIdSelector: string) {
        this.map = L.map(mapIdSelector, {
            minZoom: 2,
            //zoom: 10,
            //maxZoom: 18
        });

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 18,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
        //L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        //    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        //    maxZoom: 18,
        //    id: 'mapbox/streets-v11',
        //    tileSize: 512,
        //    zoomOffset: -1,
        //    accessToken: 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw'
        //}).addTo(this.map);

        $.getJSON(this._markerDatasetUrl,
            (data) => {
                var siteGrps: string[] = new Array<string>();

                this.mapData = JSON.parse(data);
                this.mapData.forEach((md: SimpleMapData) => {
                    var idx = siteGrps.indexOf(md.SiteGroup.toLowerCase());
                    if (idx == -1) { idx = siteGrps.push(md.SiteGroup.toLowerCase())-1 }
                    md.Marker = new L.Marker(
                        [md.Latitude, md.Longitude],
                        {
                            icon: L.divIcon({
                                html: pin,
                                className: `marker ${this._color_classes[idx % this._color_classes.length]}`,
                                iconSize: [24, 24],
                                iconAnchor: [12, 24]
                            })
                        }
                    )
                        .bindPopup(`
                            <div class="card border-none">
                                <div class="card-header ${this._color_classes[idx % this._color_classes.length]}">
                                    <h6 class="text-white">${md.SiteGroup} / ${md.SiteNickname}</h6>
                                </div>
                                <div class="card-body">
                                    <ul class="list-unstyled">
                                        <li>${md.SiteName}</li>
                                        <li>${md.SiteLocation}</li>
                                    </ul>
                                </div>
                            </div>`
                        )
                        .on('click', (e: L.LeafletMouseEvent) => { this.map.panTo(e.latlng); })
                        .addTo(this.map);
                });

                
                // https://stackoverflow.com/questions/16845614/zoom-to-fit-all-markers-in-mapbox-or-leaflet
                this.map.fitBounds(
                    new L.FeatureGroup(
                        this.mapData.map((md)=> md.Marker)
                    ).getBounds()//.pad(0.5)
                );
            }
        );
    }

    
}

interface SimpleMapData {
    SiteGroup: string,
    SiteNickname: string,
    SiteName: string,
    SiteLocation: string,
    Latitude: number,
    Longitude: number,
    Marker: L.Marker
}

//export class DataMarker extends L.Marker {
//    data: any;

//    constructor(latLng: L.LatLngExpression, data: any, options?: L.MarkerOptions) {
//        super(latLng, options);
//        this.setData(data);
//    }

//    getData() {
//        return this.data;
//    }

//    setData(data: any) {
//        this.data = data;
//    }
//}